/**
 *
 * @param {number} len
 * @param {string?} charSet
 * @returns
 */
const randomString = (len, charSet) => {
  charSet =
    charSet || "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  let randomString = "";
  for (let i = 0; i < len; i++) {
    const randomPoz = Math.floor(Math.random() * charSet.length);
    randomString += charSet.substring(randomPoz, randomPoz + 1);
  }
  return randomString;
};

/**
 *
 * @param {string} str
 * @param {number} len
 * @param {'0'} char
 * @returns
 */
const paddingLeft = (str, len, char = "0") => {
  let output = `${str}`;

  while (output.length < len) {
    output = `${char}${output}`;
  }

  return output;
};

module.exports = {
  randomString,
  paddingLeft,
};
