const { paddingLeft } = require("../helpers/string");
const { getFirebase } = require("../services/db");
const { BaseCollection } = require("./_base.collection");
const { signupLogCollection } = require("./signup-log.collection");

/**
 * @typedef StatisticsModel
 * @property {string} id
 * @property {number=} pageViews
 * @property {number=} signups
 * @property {number=} orders
 * @property {number=} revenue
 */

/**
 * @extends {BaseCollection<StatisticsModel>}
 */

class StatisticsCollection extends BaseCollection {
  /**
   * @private
   * @type {string}
   */
  _shopId;

  constructor(shopId) {
    super(`/SHOPS/${shopId}/STATISTICS`);
    this._shopId = shopId;
  }

  /**
   *
   * @param {Object} param0
   * @param {number} param0.limit
   * @param {string=} param0.startAfterId
   * @returns {PromiseLike<StatisticsModel[]>}
   */
  async findListByOptions({ limit = 25, startAfterId = null } = {}) {
    console.log("triggered:''")
    let query = this.collection.where("type", "==", "day");
    if (startAfterId) {
      query = query.startAfter(startAfterId);
    }
    return query
      .orderBy("id", "desc")
      .limit(limit)
      .get()
      .then((list) => list.docs.map((doc) => doc.data()));
  }

  /**
   * @param {string} userId
   */
  async logPageView(userId) {
    await Promise.all(
      this._getTodayIds().map(async (id) => {
        await this.collection.doc(id).set(
          {
            id,
            type: this._getType(id),
            pageViews: getFirebase().firestore.FieldValue.increment(1),
            pageViews_userIds: {
              [userId]: getFirebase().firestore.FieldValue.increment(1),
            },
          },
          { merge: true }
        );
      })
    );
  }

  /**
   * @param {import('./order.collection').OrderModel} order
   */
  async logOrder(order) {
    await Promise.all(
      this._getTodayIds().map(async (id) => {
        await this.collection.doc(id).set(
          {
            id,
            type: this._getType(id),
            orders: getFirebase().firestore.FieldValue.increment(1),
            orders_userIds: {
              [order.userId]: getFirebase().firestore.FieldValue.increment(1),
            },
            revenue: getFirebase().firestore.FieldValue.increment(
              order.subtotal
            ),
          },
          { merge: true }
        );
      })
    );
  }

  /**
   * @param {string} userId
   */
  async logSignupIfNeeded(userId) {
    const signupLog = await signupLogCollection.findByUserIdAndShopId(
      userId,
      this._shopId
    );
    if (signupLog) {
      return;
    }

    await signupLogCollection.create({
      userId,
      shopId: this._shopId,
    });
    await Promise.all(
      this._getTodayIds().map(async (id) => {
        await this.collection.doc(id).set(
          {
            id,
            type: this._getType(id),
            signups: getFirebase().firestore.FieldValue.increment(1),
          },
          { merge: true }
        );
      })
    );
  }

  /**
   * @private
   * @returns {string[]}
   */
  _getTodayIds() {
    return [
      this.getAllId(),
      this.getYearId(),
      this.getMonthId(),
      this.getTodayId(),
    ];
  }

  /**
   * @private
   * @param {string} id
   * @returns {string}
   */
  _getType(id) {
    switch (id.length) {
      case 3:
        return "all";

      case 4:
        return "year";

      case 6:
        return "month";

      case 8:
        return "day";
    }
  }

  _;

  /**
   * @returns {string}
   */
  getTodayId() {
    const now = new Date();
    const yearStr = paddingLeft(now.getFullYear(), 4);
    const monthStr = paddingLeft(now.getMonth() + 1, 2);
    const dayStr = paddingLeft(now.getDate(), 2);
    return `${yearStr}${monthStr}${dayStr}`;
  }

  /**
   * @returns {string}
   */
  getMonthId() {
    const now = new Date();
    const yearStr = paddingLeft(now.getFullYear(), 4);
    const monthStr = paddingLeft(now.getMonth() + 1, 2);
    return `${yearStr}${monthStr}`;
  }

  /**
   * @returns {string}
   */
  getYearId() {
    const now = new Date();
    const yearStr = paddingLeft(now.getFullYear(), 4);
    return `${yearStr}`;
  }

  /**
   * @returns {string}
   */
  getAllId() {
    return `ALL`;
  }
}

/**
 * @param {string} shopId
 * @returns {StatisticsCollection}
 */
const statisticsCollectionOf = (shopId) => new StatisticsCollection(shopId);

module.exports = {
  statisticsCollectionOf,
};
