import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    meta: { enableAppBar: false, signInRequired: true, paymentRequired: true },
    component: () => import(/* webpackChunkName: "signIn" */ '../views/HomePage.vue')
  },
  {
    path: '/shops/:shopName',
    component: () => import(/* webpackChunkName: "signIn" */ '../views/HomePage.vue'),
    props: (route) => ({ shop: route.query.shop }),
    meta: { enableAppBar: false, signInRequired: true, paymentRequired: true }
  },
  {
    path: '/sign-in',
    name: 'signIn',
    meta: { enableAppBar: false, signInRequired: false, paymentRequired: true },
    component: () => import(/* webpackChunkName: "signIn" */ '../views/SignIn.vue')
  },
  {
    path: '/sign-in-with-phone-number',
    name: 'signInWithPhoneNumber',
    meta: { enableAppBar: false, signInRequired: false, paymentRequired: true },
    component: () => import(/* webpackChunkName: "signIn" */ '../views/SignInWithPhoneNumber.vue')
  },
  {
    path: '/sign-out',
    name: 'signOut',
    meta: { enableAppBar: false, signInRequired: false, paymentRequired: true },
    component: () => import(/* webpackChunkName: "signIn" */ '../views/SignOut.vue')
  },
  {
    path: '/cart',
    name: 'cart',
    meta: { enableAppBar: false, signInRequired: false, paymentRequired: true },
    component: () => import(/* webpackChunkName: "signIn" */ '../views/CartPage.vue')
  },
  {
    path: '/orders',
    name: 'orders',
    meta: { enableAppBar: false, signInRequired: false, paymentRequired: true },
    component: () => import(/* webpackChunkName: "signIn" */ '../views/OrdersPage.vue')
  },
  {
    path: '/orders/:orderId',
    name: 'orderDetails',
    meta: { enableAppBar: false, signInRequired: false, paymentRequired: true },
    component: () => import(/* webpackChunkName: "signIn" */ '../views/OrderDetails.vue')
  },
  {
    path: '/orders/:orderId/address/update',
    name: 'updateOrderAddress',
    meta: { enableAppBar: false, signInRequired: false, paymentRequired: true },
    component: () => import(/* webpackChunkName: "signIn" */ '../views/UpdateOrderAddress.vue')
  },
  {
    path: '/products/:productId',
    name: 'productDetails',
    meta: { enableAppBar: false, signInRequired: false, paymentRequired: true },
    component: () => import(/* webpackChunkName: "signIn" */ '../views/ProductDetails.vue')
  },
  {
    path: '/account',
    name: 'account',
    meta: { enableAppBar: false, signInRequired: false, paymentRequired: true },
    component: () => import(/* webpackChunkName: "signIn" */ '../views/AccountPage.vue')
  },
  {
    path: '/checkout/:orderId',
    name: 'checkout',
    meta: { enableAppBar: false, signInRequired: false },
    component: () => import(/* webpackChunkName: "payment" */ '../views/CheckoutPage.vue')
  },
  {
    path: '*',
    name: 'notFound',
    component: () => import(/* webpackChunkName: "payment" */ '../views/NotFound.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach(async (to, from, next) => {
  // Check if store.state.shop.shop exists and has a uniqueId property
  if ((to.path === '/' || to.path === '/shops') && store.state.shop.shop && store.state.shop.shop.uniqueId) {
    next(`/shops/${store.state.shop.shop.uniqueId}`)
    return
  }

  // If store.state.shop.shop or uniqueId is not available, redirect to default shop
  if ((to.path === '/' || to.path === '/shops') && (!store.state.shop.shop || !store.state.shop.shop.uniqueId)) {
    window.location.href = 'https://transit-eccart.com/'
    return
  }

  next()
})

export default router
