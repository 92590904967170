const { BaseCollection } = require("./_base.collection");

/**
 * @typedef SignupLogModel
 * @property {string} id
 * @property {string} userId
 * @property {string} shopId
 */

/**
 * @extends {BaseCollection<SignupLogModel>}
 */
class SignupLogCollection extends BaseCollection {
  constructor() {
    super("SIGNUP_LOGS");
  }

  /**
   * @param {string} userId
   * @param {string} shopId
   * @returns {PromiseLike<SignupLogModel | null>}
   */
  findByUserIdAndShopId(userId, shopId) {
    return this.collection
      .where("userId", "==", userId)
      .where("shopId", "==", shopId)
      .limit(1)
      .get()
      .then((list) => (list.docs.length > 0 ? list.docs[0].data() : null));
  }
}

const signupLogCollection = new SignupLogCollection();

module.exports = {
  signupLogCollection,
};
