import format from 'date-fns/format'
import ja from 'date-fns/locale/ja'
const baseUrl =
  process.env.VUE_APP_ENVIRONMENT === 'development' ? process.env.VUE_APP_API_URL_DEV : process.env.VUE_APP_API_URL_PRO

const browserLocale = navigator.language.split('-')[0]

const formatDateTime = (dateTime) => {
  return browserLocale === 'ja'
    ? format(dateTime, 'yyyy/MM/dd (eee) HH:mm', { locale: ja })
    : format(dateTime, 'yyyy/MM/dd (eee) HH:mm')
}

async function getShopOwnerById(id) {
  // eslint-disable-next-line no-useless-catch
  try {
    const response = await fetch(`${baseUrl}/getShopOwner?shopOwnerId=${id}`)
    return response.data
  } catch (error) {
    throw error
  }
}

export { browserLocale, formatDateTime, getShopOwnerById }
