import '@/plugins/veevalidate'
import '@/plugins/vuemeta'
import { firebaseFirestore, firebase } from '@/services/firebase'
import { initShared } from '@shared/index'
import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import router from './router'
import store from './store'

// import './print'

initShared(firebaseFirestore, firebase)

Vue.config.productionTip = false

Vue.use({ vuetify })

Vue.prototype.$asyncEmit = function (propKey, ...args) {
  const prop = this.$props[propKey]
  if (!prop || typeof prop !== 'function') {
    return Promise.resolve(false)
  }
  return Promise.resolve(prop(...args))
}

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App)
}).$mount('#app')
