<template>
  <v-app>
    <v-app-bar app v-if="enableAppBar" elevation="0" color="white">
      <v-app-bar-nav-icon @click="toggleNavDrawer" />
      <v-spacer></v-spacer>
      <v-app-bar-title>{{ appBarTitle }}</v-app-bar-title>
      <v-spacer></v-spacer>
    </v-app-bar>
    <navigation-drawer v-if="enableNavDrawer" />
    <v-main>
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
import './App.css'
import NavigationDrawer from './components/NavigationDrawer'
import { createNamespacedHelpers } from 'vuex'
import { firebaseAuth, firebaseFirestore } from '@/services/firebase'
import { shopCollection } from '@shared/collections/shop.collection'
import { statisticsCollectionOf } from '@shared/collections/statistics.collection'

const navDrawerStore = createNamespacedHelpers('navDrawer')
const userStore = createNamespacedHelpers('user')
const shopStore = createNamespacedHelpers('shop')

export default {
  name: 'App',
  components: {
    NavigationDrawer
  },
  data: () => ({
    unsubscribe: null,
    isPageViewSent: false
  }),
  computed: {
    ...userStore.mapState(['userId', 'user']),
    ...shopStore.mapState(['shopId', 'shop']),
    enableAppBar() {
      return this.$route.meta.enableAppBar
    },
    appBarTitle() {
      return this.$route.meta.appBarTitle
    },
    enableNavDrawer() {
      return this.$route.meta.enableNavDrawer
    },
    shopUniqueId() {
      return this.$route.params.shopName
    }
  },
  methods: {
    ...navDrawerStore.mapActions(['toggleNavDrawer']),
    ...userStore.mapActions(['updateUser']),
    ...shopStore.mapActions(['updateShop']),
    async loadUser() {
      console.log('start')
      firebaseAuth.onAuthStateChanged((user) => {
        if (user) {
          if (this.unsubscribe) {
            this.unsubscribe()
          }
          this.unsubscribe = firebaseFirestore
            .collection('USERS')
            .doc(user.uid)
            .onSnapshot((snap) => {
              this.updateUser({
                id: snap.id,
                ...snap.data()
              })
            })
        } else {
          this.updateUser(null)
        }
        this.sendPageViewIfNeeded()
      })
    },
    async loadShop() {
      const { shopUniqueId } = this
      let shop = null
      if (shopUniqueId) {
        if (this.shop && this.shop.uniqueId !== shopUniqueId) {
          this.updateShop(null)
        }
        shop = await shopCollection.findByUniqueId(shopUniqueId)
      } else if (this.shopId) {
        shop = await shopCollection.find(this.shopId)
      }
      this.updateShop(shop)
      this.sendPageViewIfNeeded()
    },
    async sendPageViewIfNeeded() {
      if (this.isPageViewSent) {
        return
      }
      const { shopId, userId } = this
      if (shopId && userId) {
        this.isPageViewSent = true
        await statisticsCollectionOf(shopId).logPageView(userId)
      }
    },
    forceNavbarUpdate() {
      this.$forceUpdate()
    }
  },
  watch: {
    userId() {
      this.sendPageViewIfNeeded()
    },
    shopId() {
      this.sendPageViewIfNeeded()
    },
    $route() {
      this.loadShop()
      this.forceNavbarUpdate()
    }
  },
  mounted() {
    this.loadUser()
    setTimeout(() => {
      this.loadShop()
    }, 300)
  }
}
</script>
