export const state = () => ({
  cart: {}
})

const MAX_QUANTITY = 9

export const mutations = {
  addToCart(state, { shopId, productId, quantity }) {
    const list = state.cart[shopId] || []
    const item = list.find((v) => v.productId == productId)
    if (item) {
      item.quantity = Math.min(item.quantity + quantity, MAX_QUANTITY)
      state.cart[shopId] = list
    } else {
      state.cart[shopId] = [...list, { productId, quantity: Math.min(quantity, MAX_QUANTITY) }]
    }
    state.cart = { ...state.cart }
  },
  updateCart(state, { shopId, productId, quantity }) {
    const list = state.cart[shopId] || []
    const item = list.find((v) => v.productId == productId)
    if (item) {
      item.quantity = Math.min(quantity, MAX_QUANTITY)
      state.cart[shopId] = list
    } else {
      state.cart[shopId] = [...list, { productId, quantity: Math.min(quantity, MAX_QUANTITY) }]
    }
    state.cart = { ...state.cart }
  },
  removeFromCart(state, { shopId, productId }) {
    const list = state.cart[shopId] || []
    state.cart[shopId] = list.filter((v) => v.productId != productId)
    state.cart = { ...state.cart }
  },
  clearCart(state, { shopId }) {
    delete state.cart[shopId]
    state.cart = { ...state.cart }
  }
}

export const actions = {
  addToCart({ commit }, { shopId, productId, quantity }) {
    commit('addToCart', { shopId, productId, quantity })
  },
  updateCart({ commit }, { shopId, productId, quantity }) {
    commit('updateCart', { shopId, productId, quantity })
  },
  removeFromCart({ commit }, { shopId, productId }) {
    commit('removeFromCart', { shopId, productId })
  },
  async clearCart({ commit }, { shopId }) {
    commit('clearCart', { shopId })
  }
}
