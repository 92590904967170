export const state = () => ({
  shopId: null,
  shop: null
})

export const mutations = {
  updateShop(state, value) {
    state.shopId = value ? value.id : null
    state.shop = value
  }
}

export const actions = {
  async updateShop({ commit }, shop) {
    commit('updateShop', shop)
  },
  async clearShop({ commit }) {
    commit('updateShop', null)
  }
}
